<script lang="ts" setup>
import { CaretTop } from "@element-plus/icons-vue";
import { useTransition } from "@vueuse/core";
import { Warning } from "@element-plus/icons-vue";
import { fetchStatisticDashboard } from "@/api";
const { t } = useI18n();
const stats = useCookie("stats");

const loading = ref(true);

const totalUsersRef = ref(0);
const totalUsers = useTransition(totalUsersRef, {
  duration: 2000,
});

const dailyUsersRef = ref(0);
const dailyUsers = useTransition(dailyUsersRef, {
  duration: 1000,
});

const totalOrdersRef = ref(0);
const totalOrders = useTransition(totalOrdersRef, {
  duration: 1500,
});

const totalOffersRef = ref(0);
const totalOffers = useTransition(totalOffersRef, {
  duration: 500,
});

const averageReg = ref(0);
const averageOrders = ref(0);
const activeUsersPcs = ref(0);
const offers24h = ref(0);

const getNewData = async () => {
  const stat = await fetchStatisticDashboard();
  totalUsersRef.value = stat.allUsers;
  dailyUsersRef.value = stat.activeUsers24h;
  averageReg.value = stat.newUsers24h;
  totalOrdersRef.value = stat.allOrders;
  averageOrders.value = stat.orders24h;
  totalOffersRef.value = stat.allOffers;
  activeUsersPcs.value = +(
    (stat.activeUsers24h * 100) /
    stat.activeUsers48h
  ).toFixed(2);
  offers24h.value = stat.offers24h;
};
let int: any;
const controlledInt = () => {
  int = setTimeout(async () => {
    await getNewData();
    controlledInt();
  }, 10000);
};
onMounted(async () => {
  await getNewData();
  loading.value = false;
  controlledInt();
});
onUnmounted(() => {
  clearTimeout(int);
});
</script>

<template>
  <el-row :gutter="20" class="stats-row" v-if="stats === 'shown'">
    <el-col :span="12">
      <div class="statistic-card">
        <el-skeleton v-if="loading" :rows="2" animated></el-skeleton>
        <template v-else>
          <el-statistic :value="Number(totalUsers)">
            <template #title>
              <div style="display: inline-flex; align-items: center">
                {{ t("c-widget-Stats-total-users") }}
              </div>
            </template>
          </el-statistic>
          <div class="statistic-footer text-center">
            <div class="footer-item">
              <span>{{ t("c-widget-Stats-av-reg-per-day") }}:</span>
              <span class="green">
                {{ averageReg }}
              </span>
            </div>
          </div>
        </template>
      </div>
    </el-col>
    <el-col :span="12">
      <div class="statistic-card">
        <el-skeleton v-if="loading" :rows="2" animated></el-skeleton>
        <template v-else>
          <el-statistic :value="Number(dailyUsers)">
            <template #title>
              <div style="display: inline-flex; align-items: center">
                {{ t("c-widget-Stats-daily-active-users") }}
                <el-tooltip
                  effect="dark"
                  :content="t('c-widget-Stats-daily-active-users-tip')"
                  placement="top"
                >
                  <el-icon style="margin-left: 4px" :size="12">
                    <Warning />
                  </el-icon>
                </el-tooltip>
              </div>
            </template>
          </el-statistic>
          <div class="statistic-footer text-center">
            <div class="footer-item">
              <span>{{ t("c-widget-Stats-than-yesterday") }}</span>
              <span class="green">
                {{ activeUsersPcs > 0 ? activeUsersPcs : 1 }}%
                <el-icon>
                  <CaretTop />
                </el-icon>
              </span>
            </div>
          </div>
        </template>
      </div>
    </el-col>
    <el-col :span="12">
      <div class="statistic-card">
        <el-skeleton v-if="loading" :rows="2" animated></el-skeleton>
        <template v-else>
          <el-statistic :value="Number(totalOrders)">
            <template #title>
              <div style="display: inline-flex; align-items: center">
                {{ t("c-widget-Stats-total-orders") }}
              </div>
            </template>
          </el-statistic>
          <div class="statistic-footer text-center">
            <div class="footer-item">
              <span>{{ t("c-widget-Stats-av-orders-per-day") }}:</span>
              <span class="green">
                {{ averageOrders }}
              </span>
            </div>
          </div>
        </template>
      </div>
    </el-col>
    <el-col :span="12">
      <div class="statistic-card">
        <el-skeleton v-if="loading" :rows="2" animated></el-skeleton>
        <template v-else>
          <el-statistic :value="Number(totalOffers)">
            <template #title>
              <div style="display: inline-flex; align-items: center">
                {{ t("c-widget-Stats-total-offers") }}
              </div>
            </template>
          </el-statistic>
          <div class="statistic-footer text-center">
            <div class="footer-item">
              <span>{{ t("c-widget-Stats-new-offers-24h") }}</span>
              <span class="green">
                {{ offers24h }}
              </span>
            </div>
          </div>
        </template>
      </div>
    </el-col>
  </el-row>
</template>

<style scoped lang="scss">
.stats-row {
  text-align: center;
  .el-col {
    margin-bottom: 20px;
  }
}
:global(h2#card-usage ~ .example .example-showcase) {
  background-color: var(--el-fill-color) !important;
}

.el-statistic {
  --el-statistic-content-font-size: 28px;
}

.statistic-card {
  padding: 20px;
  border-radius: 4px;
  background-color: #7771;
}

.statistic-footer {
  font-size: 12px;
  color: var(--el-text-color-regular);
  margin-top: 16px;
}

.statistic-footer .footer-item span:last-child {
  align-items: center;
  margin-left: 4px;
  * {
    vertical-align: middle;
  }
}

.green {
  color: var(--el-color-success);
}
.red {
  color: var(--el-color-error);
}
</style>
