import { request } from "@/api";

interface Statistic {
  activeUsers24h: number;
  activeUsers48h: number;
  allOffers: number;
  allOrders: number;
  allUsers: number;
  newUsers24h: number;
  offers24h: number;
  orders24h: number;
}

export const fetchStatisticDashboard = async () => {
  return request<Statistic>("/platform-statistic");
};
